const scale = {
  ZERO: {
    color: "blue",
    display: "Zero",
    name: "zero"
  },
  LOW: {
    color: "green",
    display: "Low",
    name: "low"
  },
  MEDIUM: {
    color: "yellow",
    display: "Medium",
    name: "medium"
  },
  HIGH: {
    color: "orange",
    display: "High",
    name: "high"
  },
  OFF: {
    color: "red",
    display: "Off the Scale",
    name: "off"
  },
}

export default scale;