const Routes = [
  {
    displayName: "History",
    path: "/history",
    name: "history",
  },
  {
    displayName: "Home",
    path: "/",
    name: "home",
  },
  {
    displayName: "Races",
    path: "/races",
    name: "races",
  },
  {
    displayName: "Race",
    path: "/races/:raceId",
    name: "raceById",
  },
  {
    displayName: "Rules",
    path: "/rules",
    name: "rules",
  },
  {
    displayName: "Runners",
    path: "/runners",
    name: "runners",
  },
  {
    displayName: "Runner",
    path: "/runners/:runnerId",
    name: "runnerById",
  },
];

const getRoute = (routeName) => {
  return Routes.find((route) => route.name === routeName);
}

const getRaceRoute = (raceId) => {
  // need to clone this so that we don't muck with the routes object
  let raceById = {...Routes.find((route) => route.name === "raceById")};

  raceById.path = raceById.path.replace(":raceId", raceId);

  return raceById;
}

const getRunnerRoute = (runnerId) => {
  // need to clone this so that we don't muck with the routes object
  let runnerById = {...Routes.find((route) => route.name === "runnerById")};

  runnerById.path = runnerById.path.replace(":runnerId", runnerId);

  return runnerById;
}

export {
  Routes,
  getRaceRoute,
  getRoute,
  getRunnerRoute
};