import React from 'react';

import RaceList from "../components/RaceList";

function Races() {
  return (
    <div className="container">
      <div className="row my-4">
        <div className="col">
          <h1>Races</h1>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          <RaceList sticky={true} />
        </div>
      </div>
    </div>
  );
}

Races.propTypes = {}

Races.defaultProps = {};

export default Races;
