import React, { useState, useEffect, createContext, useContext } from "react";

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

/**
 * BreakpointProvider provides a method for checking screen dimensions against a configured set of breakpoints for downstream functionality decisions.
 *
 * This is heavily based on the following solution:
 * https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
 *
 * @since Version 0.1.0
 */
const BreakpointProvider = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    let optionalQueries = queries;
    if (optionalQueries === null || optionalQueries === undefined) {
      optionalQueries = {};
    }

    const combinedQueries = {
      ...optionalQueries,
      xs: "(max-width: 575.98px)",
      sm: "(min-width: 576px) and (max-width: 767.98px)",
      md: "(min-width: 768px) and (max-width: 991.98px)",
      lg: "(min-width: 992px) and (max-width: 1199.98px)",
      xl: "(min-width: 1200px)"
    };

    const mediaQueryLists = {};
    const keys = Object.keys(combinedQueries);

    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof combinedQueries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(combinedQueries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof combinedQueries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof combinedQueries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

// utility for re-use of identifying breakpoint matches
const buildBreakpointMatchList = (context) => {
  let breakpointMatches = "";
  const myKeys = Object.keys(context);
  if (myKeys !== null && myKeys !== undefined && myKeys.length !== 0) {
    breakpointMatches = myKeys.reduce((accumulator, currentValue) => {
      return context[currentValue] ? accumulator + currentValue : accumulator;
    }, "");
  }

  return breakpointMatches;
};

const useBreakpoint = () => {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }

  return context;
};

const useCurrentBreakpoint = () => {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error(
      "useCurrentBreakpoint must be used within BreakpointProvider"
    );
  }

  return buildBreakpointMatchList(context);
};

/*
  The size of the application header changes based on breakpoint. This hook allows access to the specific offset size of the header for a given breakpoint.
*/
const useHeaderOffset = () => {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error("useHeaderOffset must be used within BreakpointProvider");
  }

  const matchedBreakpoint = buildBreakpointMatchList(context);

  let headerOffset = 0;
  switch (matchedBreakpoint) {
    case "xs":
    case "sm":
      headerOffset = -51;
      break;
    case "md":
      headerOffset = -121;
      break;
    case "lg":
    case "xl":
    default:
      headerOffset = 0;
      break;
  }

  return headerOffset;
};

export {
  useBreakpoint,
  useCurrentBreakpoint,
  useHeaderOffset,
  BreakpointProvider
};
