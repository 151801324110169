import React from "react";
import { Link } from "react-router-dom";

import RaceList from "../components/RaceList";

import { getRoute } from "../utils/routeUtility";

function Home() {
  return (
    <div className="container">
      <div className="row my-4">
        <div className="col">
          <q className="h2">First call three mile run!</q>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          <h2 className="d-inline-block me-2">Past Races</h2>
          <Link className="d-inline-block align-middle" to={getRoute("races").path}>View All Races</Link>
          <RaceList size={5} />
        </div>
      </div>
    </div>
  );
}

Home.propTypes = {}

export default Home;
