import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { getRoute, getRunnerRoute } from "../utils/routeUtility";

function Navigation() {
  const runners = useSelector(state => state.support.runners);

  return (
    <Navbar expand="lg">
      <Navbar.Brand as={Link} to={getRoute("home").path}>Hellman 3 Mile Run</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to={getRoute("home").path}>Home</Nav.Link>
          <Nav.Link as={Link} to={getRoute("history").path}>History</Nav.Link>
          <Nav.Link as={Link} to={getRoute("rules").path}>Rules</Nav.Link>
          <Nav.Link as={Link} to={getRoute("races").path}>Races</Nav.Link>
          <NavDropdown title="Runners" id="basic-nav-dropdown">
            {runners.map((runner) => {
              return (
                <NavDropdown.Item
                  as={Link} 
                  to={getRunnerRoute(runner.id).path}
                  key={`runner-${runner.id}`}
                >
                  {`${runner.firstName} ${runner.lastName}`}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

Navigation.propTypes = {}

export default Navigation;