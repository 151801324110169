import React from 'react';
import PropTypes from "prop-types";

import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Scale from "../utils/scale";

function ConditionIcon({condition, value}) {

  let iconName = "";
  let iconColor = "green";
  switch(condition) {
    case "airQuality":
      if (value === "muggy"){
        iconName = "lungs";
        iconColor = "dark-blue";
      } else if (value === "sticky"){
        iconName = "hand-holding-water";
        iconColor = "dark-blue";
      } else if (value === "dusty"){
        iconName = "cloud-meatball";
        iconColor = "tan";
      } 

      break;
    case "bugs":
      iconName = "bug";
      iconColor = "green";
      break;
    case "footing":
      iconName = "shoe-prints";

      if (value === "icy" || value === "snowy"){
        iconColor = "light-blue";
      } else if (value === "muddy"){
        iconColor = "brown";
      } else if (value === "soft" || value === "loose gravel"){
        iconColor = "tan";
      }

      break;
    case "humidity":
      iconName = "tint";
      iconColor = Scale.OFF.color;
      break;
    case "pollen":
      iconName = "seedling";
      iconColor = Scale.OFF.color;
      break;
    case "precipitation":
      if (value === "blizzard"){
        iconName = "snowflake";
        iconColor = "light-blue";
      } else if (value === "rain" || value === "wet"){
        iconName = "cloud-rain";
        iconColor = "blue";
      } else if (value === "sleet"){
        iconName = "cloud-showers-heavy";
        iconColor = "light-blue";
      } 

      break;
    case "temperature":
      const tempPrefix = "thermometer-";
      
      if (value < 15){
        iconName = tempPrefix + "empty";
        iconColor = "light-blue";
      } else if (value >= 15 && value < 32){
        iconName = tempPrefix + "quarter";
        iconColor = "blue";
      } else if (value >= 32 && value < 50){
        iconName = tempPrefix + "half";
        iconColor = "dark-blue";
      } else if (value >= 51 && value < 75){
        iconName = tempPrefix + "three-quarters";
        iconColor = "green";
      } else if (value >= 76 && value < 90){
        iconName = tempPrefix + "three-quarters";
        iconColor = "orange";
      } else {
        iconName = tempPrefix + "full";
        iconColor = "red";
      }

      break;
    case "windSpeed":
      iconName = "wind";
      
      if (value === 0){
        iconColor = Scale.ZERO.color;
      } else if (value > 0 && value < 10){
        iconColor = Scale.LOW.color;
      } else if (value >= 10 && value < 25){
        iconColor = Scale.MEDIUM.color;
      } else if (value >= 25 && value < 40){
        iconColor = Scale.HIGH.color;
      } else {
        iconColor = Scale.OFF.color;
      }

      break;
    default:
      iconName = "frog"
      break;
  }

  return (
    <div className={classnames("p-1", "d-inline-block", `text-${iconColor}`)}>
      <FontAwesomeIcon fixedWidth icon={iconName}/>
    </div>
  );
}

ConditionIcon.propTypes = {
  /** the condition to display */
  condition: PropTypes.oneOf([
    "airQuality",
    "bugs",
    "footing",
    "humidity",
    "pollen",
    "precipitation",
    "temperature",
    "windSpeed"
  ]).isRequired,
  /** the value of the condition */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

ConditionIcon.defaultProps = {
  value: ""
}

export default ConditionIcon;