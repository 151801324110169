import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import axios from "axios";
import classnames from "classnames";

import { useCurrentBreakpoint } from "../providers/breakpoint/";

import RaceListItem from "./RaceListItem";
import RaceDetail from "./RaceDetail";
import Spinner from "./Spinner";
import { getRaceDate } from "../utils/raceUtility";

function RaceList({size, sticky}) {
  const [races, setRaces] = useState([]);
  const [selectedRaceId, setSelectedRaceId] = useState(null);

  const runners = useSelector(state => state.support.runners);

  const breakpoint = useCurrentBreakpoint();

  // LOAD RACES
  useEffect(() => {
    axios.get("https://www.hellman3mile.run/api/v1/races")
      .then((response) => {
        setRaces(response.data);
        setSelectedRaceId(response.data[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelect = (raceId) => {
    setSelectedRaceId(raceId);
  }

  let isMobile = false;
  switch (breakpoint){
    case "xs":
    case "sm":
    case "md":
      isMobile = true;
      break;
    case "lg":
    case "xl":
    default:
      isMobile = false;
      break;
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <Accordion>
          {races && !!races.length && races.slice(0, size).map((race) => {
            return (
              <Card key={"race-" + race.id}>
                <Accordion.Toggle
                  as={Card.Header} 
                  eventKey={"race-" + race.id}
                  onClick={() => handleSelect(race.id)}
                >
                  <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      <h3 className="m-0">{getRaceDate(race.date._seconds)}</h3>
                      {race.participants && !!race.participants.length && (
                        <div className="d-inline-block m-3">
                          <span
                            className={classnames("badge rounded-pill", selectedRaceId === race.id ? "bg-white text-dark" : "bg-secondary text-light")}
                          >
                            {race.participants.length}
                          </span>
                          {" "}Participants
                        </div>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      {selectedRaceId === race.id && <FontAwesomeIcon icon="arrow-down" />}
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"race-" + race.id}>
                  <Card.Body>
                    {!!selectedRaceId && selectedRaceId === race.id &&
                      <RaceDetail
                        raceId={selectedRaceId}
                        runners={runners}
                        showHeading={false}
                        variant="inset"
                      />
                    }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      ) : (
        <div className="row">
          <div className="col-12 col-md-6 mr-0">
            <ul className="list-group">
              {races && !!races.length && !!selectedRaceId && races.slice(0, size).map((race) => {
                return (
                  <RaceListItem 
                    key={"race-" + race.id}
                    handleSelect={handleSelect}
                    race={race}
                    selected={selectedRaceId === race.id}
                  />
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-md-6 ml-0">
            {!!selectedRaceId ? (
              <RaceDetail
                raceId={selectedRaceId}
                runners={runners}
                sticky={sticky}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

RaceList.propTypes = {
  /* Optional size of the list to render */
  size: PropTypes.number,
  /* Optional setting for making the detail sticky at the top of the screen */
  sticky: PropTypes.bool
}

RaceList.defaultProps = {
  size: 10000, // default to a size large enough to display all
  sticky: false
}

export default RaceList;