import React from 'react';

import BlockQuote from "../components/BlockQuote";

function History() {
  return (
    <div className="container">
      <div className="row my-4">
        <div className="col">
          <h1>History</h1>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          <h2>Toe the Starting Line: How It Began</h2>
          <p>Every story has a beginning. A reason for being. The Hellman 3 Mile Run is no different.</p>
          <BlockQuote caption="Roger">
            <p>I think the precursor to the official race was Darrel following me around the
            countryside in a '64 Chevy drinking beer around 1972-1974. He eventually
            decided that maybe this running thing can be fun and gave it a try and was
            pretty good at it. As he competed in numerous marathons, I assume the
            trip around the block became something he could do in his sleep.</p>
          </BlockQuote>
          <BlockQuote caption="Larry">
            <p>I can't remember exactly how it first started but I do remember 
              Roger getting the poster board started following that very first run. 
              As you can see we anticipated who would eventually have the most entries 
              which is why Bob's box is so small!</p>
          </BlockQuote>
          <h2>Planning and Preparation: First Call 3 Mile Run!</h2>
          <p>World class athletes everywhere have routines they use to prepare for competition. The Hellman 3 Mile Run is no different. Competitors use a variety of techniques to mentally and physically prepare - with varying degrees of success and earnestness.</p>
          <BlockQuote caption="Larry">
            <p>I remember when I was in great shape and in college I would always beg everyone to run around the block with me for the race but as we all got older the run was procrastinated somewhat during a long weekend...Labor day, 4th of July, and Thanksgiving seemed to be common holidays for the big run...We generally tried to get our run in early in the day because the beer was cold and was calling our name...There were times I remember that we baled hay and then did our run!</p>
          </BlockQuote>
          <BlockQuote caption="Roger">
            <p>As with anything, anticipation played such an important role. So "first call, three mile run" would commence several hours before the official race. It’s main purpose was obvious, back off the beer boys, we have something important coming up.</p>
          </BlockQuote>
          <h2>The Conditions: How Races are Won and Lost</h2>
          <p>Situated in America's heartland amongst the pastures and fields, the Hellman 3 Mile Run provides a unique set of challenges to navigate that vary from race to race. Foremost in the mind is the dedication to the race in all seasons and conditions. The competitors persevere through wind, ice, rain, snow, heat - and those are just the elements! Local flora and fauna also contributes to adverse conditions.</p>
          <BlockQuote caption="Larry">
            <p>As our times started to slow down over the years we always wanted to log on the board ANY thing that made the run tougher. 10 mph winds easily became 20 mph with gusts up to 30! "Hot" was used for any temperature over 80. If one person ate a gnat during the run it was recorded as "Buggy" for all of us...I believe the word "hangover" may appear on the board because we would do the run sometimes the day after we drank a quite a bit of beer! The official timer, Gramps, generally kept a keg on tap in the basement fridge for these big weekends when we all got together.</p>
          </BlockQuote>
        </div>
      </div>
    </div>
  );
}

History.propTypes = {}

History.defaultProps = {};

export default History;
