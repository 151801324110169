import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { useCurrentBreakpoint } from "../providers/breakpoint/";

import RunnerRaceListItem from "./RunnerRaceListItem";
import RaceDetail from "./RaceDetail";
import Spinner from "./Spinner";

import { getRaceDate } from "../utils/raceUtility";

function RunnerRaceList({raceRunners}) {
  const defaultRunner = !!raceRunners.length ? raceRunners[0] : null;
  const [selectedRaceRunner, setSelectedRaceRunner] = useState(defaultRunner);

  const breakpoint = useCurrentBreakpoint();

  const runners = useSelector(state => state.support.runners);

  const handleSelect = (raceRunner) => {
    setSelectedRaceRunner(raceRunner);
  }

  let isMobile = false;
  switch (breakpoint){
    case "xs":
    case "sm":
    case "md":
      isMobile = true;
      break;
    case "lg":
    case "xl":
    default:
      isMobile = false;
      break;
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <Accordion>
          {raceRunners && !!raceRunners.length && raceRunners.map((raceRunner) => {
            return (
              <Card key={"raceRunner-" + raceRunner.id}>
                <Accordion.Toggle
                  as={Card.Header} 
                  eventKey={"raceRunner-" + raceRunner.id}
                  onClick={() => handleSelect(raceRunner)}
                >
                  <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      <h3 className="m-0">
                        {getRaceDate(raceRunner.date._seconds)}
                      </h3>
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-start">
                        <span className="ms-3">{raceRunner.time}</span>
                    </div>
                    <div className="col-3 d-flex justify-content-end align-items-center">
                      {selectedRaceRunner.id === raceRunner.id && <FontAwesomeIcon icon="arrow-down" />}
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"raceRunner-" + raceRunner.id}>
                  <Card.Body>
                    {!!selectedRaceRunner && selectedRaceRunner.id === raceRunner.id && 
                      <RaceDetail
                        raceId={selectedRaceRunner.raceId}
                        runners={runners}
                        showHeading={false}
                        variant="inset"
                      />
                    }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      ) : (
        <div className="row">
          <div className="col-12 col-md-6 mr-0">
            <ul className="list-group">
              {raceRunners && !!raceRunners.length && raceRunners.map((raceRunner) => {
                return (
                  <RunnerRaceListItem 
                    key={"raceRunner-" + raceRunner.id}
                    handleSelect={handleSelect}
                    raceRunner={raceRunner}
                    selected={selectedRaceRunner.id === raceRunner.id}
                  />
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-md-6 ml-0">
            {!!selectedRaceRunner ? (
              <RaceDetail
                raceId={selectedRaceRunner.raceId}
                runners={runners}
                sticky={true}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

RunnerRaceList.propTypes = {
  /* the list of runners to match on */
  raceRunners: PropTypes.arrayOf(PropTypes.object).isRequired
}

RunnerRaceList.defaultProps = {}

export default RunnerRaceList;