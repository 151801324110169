import React from 'react';
import PropTypes from "prop-types";

function BlockQuote({caption, children: quote}) {
  return (
    <figure className="border rounded bg-light m-4 p-4">
      <blockquote class="blockquote">
        {quote}
      </blockquote>
      <figcaption class="blockquote-footer">
        {caption}
      </figcaption>
    </figure>
  );
}

BlockQuote.propTypes = {
  /** the quote to render */
  quote: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  /** the value of the caption displayed below the quote */
  caption: PropTypes.string
}

BlockQuote.defaultProps = {
  caption: ""
}

export default BlockQuote;