import React, { useEffect, useState } from "react";
import {
  Route,
  Switch
} from "react-router-dom";

import { useDispatch } from "react-redux";

import axios from "axios";

import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faArrowDown,
  faArrowRight,
  faBandAid,
  faBug,
  faCloudMeatball,
  faCloudRain,
  faCloudShowersHeavy,
  faFrog,
  faHandHoldingWater,
  faLungs,
  faRunning,
  faSeedling,
  faShoePrints,
  faSnowflake,
  faThermometerEmpty,
  faThermometerFull,
  faThermometerHalf,
  faThermometerQuarter,
  faThermometerThreeQuarters,
  faTint,
  faWind 
} from '@fortawesome/free-solid-svg-icons';

// components
import Navigation from "./components/Navigation";
import Spinner from "./components/Spinner";

// views
import Home from "./view/Home";
import Races from "./view/Races";
import Runner from "./view/Runner";
import History from "./view/History";

library.add(faArrowDown);
library.add(faArrowRight);
library.add(faBandAid);
library.add(faBug);
library.add(faCloudMeatball);
library.add(faCloudRain);
library.add(faCloudShowersHeavy);
library.add(faFrog);
library.add(faHandHoldingWater);
library.add(faLungs);
library.add(faRunning);
library.add(faSeedling);
library.add(faShoePrints);
library.add(faSnowflake);
library.add(faThermometerEmpty);
library.add(faThermometerFull);
library.add(faThermometerHalf);
library.add(faThermometerQuarter);
library.add(faThermometerThreeQuarters);
library.add(faTint);
library.add(faWind);

function App() {

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  // LOAD RUNNERS
  useEffect(() => {
    axios.get("https://www.hellman3mile.run/api/v1/runners")
      .then((response) => {
        dispatch({
          type: "SET_RUNNERS",
          payload: response.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <div className="App">
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <header className="App-header container-fluid bg-light p-0">
            <div className="container">
              <Navigation />
            </div>
          </header>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/races">
              <Races />
            </Route>
            <Route exact path="/runners/:runnerId">
              <Runner />
            </Route>
            <Route exact path="/history">
              <History />
            </Route>
          </Switch>
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
