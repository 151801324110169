import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import axios from "axios";

import RunnerRaceList from "../components/RunnerRaceList";
import Spinner from "../components/Spinner";

function Runner() {

  const [runner, setRunner] = useState(null);
  const [loadingRunner, setLoadingRunner] = useState(true);

  let { runnerId } = useParams();
  
  // MATCH RUNNER DATA
  useEffect(() => {
    axios.get(`https://www.hellman3mile.run/api/v1/runners/${runnerId}`)
      .then((response) => {
        const runner = response.data;

        setRunner(runner);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingRunner(false);
      });
  }, [runnerId, setLoadingRunner, setRunner]);

  return (
    <div className="container">
      {loadingRunner ? (
        <div className="row my-4">
          <div className="col">
            <Spinner />
          </div>
        </div>
      ) : (
        <React.Fragment>
          {runner ? (
            <React.Fragment>
              <div className="row my-4">
                <div className="col">
                  <h1>{runner.firstName} {runner.lastName}</h1>
                </div>
              </div>
              <div className="row my-4">
                <div className="col">
                  <RunnerRaceList raceRunners={runner.races} />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="row my-4">
              <div className="col">
                <p className="text-danger">Error loading the runner data.</p>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

Runner.propTypes = {};

Runner.defaultProps = {};

export default Runner;
