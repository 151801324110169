import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import axios from "axios";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConditionIcon from "./ConditionIcon";
import Spinner from "./Spinner";
import { getRaceDate } from "../utils/raceUtility";

function RaceDetail({raceId, runners, showHeading, sticky, variant}) {
  const [raceData, setRaceData] = useState(null);
  const [loadingRaceData, setloadingRaceData] = useState(true);

  // LOAD RACES
  useEffect(() => {
    setloadingRaceData(true);
    axios.get(`https://www.hellman3mile.run/api/v1/races/${raceId}`)
      .then((response) => {
        let raceData = {...response.data};

        const participants = response.data.participants.map((participant) => {
          const match = runners.find((runner) => runner.id === participant.runnerId);

          participant.firstName = match.firstName;
          participant.lastName = match.lastName;

          return participant;
        });

        //update the response data with the updated participant detail
        raceData.participants = participants;

        setRaceData(raceData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setloadingRaceData(false);
      });
  }, [raceId, runners]);

  return (
    <div className={classnames(variant === "inset" ? "" : "border rounded p-3", sticky ? "sticky-top" : "")}>
      {!!!raceData ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {showHeading && (
            <h4>{getRaceDate(raceData.date._seconds)}</h4>
          )}
          {raceData.raceName && (
            <div className="font-weight-bold">{raceData.raceName}</div>
          )}
          {raceData.location && (
            <div>
              <span className="font-weight-bold">Location: </span>
              {raceData.location}
            </div>
          )}
          <hr />
          <h5>Conditions</h5>
          {raceData.temperature >= 0 && (
            <div>
              <ConditionIcon condition="temperature" value={raceData.temperature} />
              {raceData.temperature}{" "}&#8457;
            </div>
          )}
          {raceData.windSpeed >= 0 && (
            <div>
              <ConditionIcon condition="windSpeed" value={raceData.windSpeed} />
              {raceData.windSpeed}{" "}{raceData.windDirection}
            </div>
          )}
          {raceData.precipitation && raceData.precipitation.split(",").map((precip) => {
            const trmmedPrecip = precip.trim();
    
            return (
              <div key={`precip-${trmmedPrecip}`}>
                <ConditionIcon condition="precipitation" value={trmmedPrecip} />
                {trmmedPrecip}
              </div>
            );
          })}
          {raceData.pollen && (
            <div>
              <ConditionIcon condition="pollen" />
              pollen {raceData.pollen}
            </div>
          )}
          {raceData.airQuality && raceData.airQuality.split(",").map((air) => {
            const trimmedAir = air.trim();
            return (
              <div key={`airQuality-${trimmedAir}`}>
                <ConditionIcon condition="airQuality" value={trimmedAir} />
                {trimmedAir}
              </div>
            );
          })}
          {raceData.bugs && (
            <div>
              <ConditionIcon condition="bugs" />
              bugs
            </div>
          )}
          {raceData.footing && (
            <div>
              <ConditionIcon condition="footing" value={raceData.footing} />
              {raceData.footing} footing
            </div>
          )}
          {raceData.humidity && (
            <div>
              <ConditionIcon condition="humidity" value={raceData.humidity} />
              humid
            </div>
          )}
          {raceData.raceNotes && (
            <React.Fragment>
              <hr />
              <h5>Race Notes</h5>
              <ul>
              {raceData.raceNotes.split(",").map((raceNote, idx) => {
                const trimmedNote = raceNote.trim();
    
                return (
                  <li key={`race-note-${idx}`}>{trimmedNote}</li>
                );
              })}
              </ul>
            </React.Fragment>
          )}
          <hr />
          <h5>Results</h5>
          {loadingRaceData ? (
            <Spinner />
          ) : (
            <React.Fragment>
              {raceData.participants && !!raceData.participants.length ? (
                <ol>
                  {raceData.participants.map((participant, idx) => {
                    return (
                      <li key={`participant-${participant.id}`}>
                        {participant.time} - {participant.firstName} {participant.lastName}
                        {participant.ailments ? (
                          <span className="text-danger ms-2">
                            <FontAwesomeIcon icon="band-aid" className="me-2" transform={{rotate: 45}} />
                            {participant.ailments}
                          </span>
                        ) : (
                          <React.Fragment />
                        )}
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <p>No results provided.</p>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

RaceDetail.propTypes = {
  /** the raceId to render data for */
  raceId: PropTypes.string.isRequired,
  /** the list of runners to match on */
  runners: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Optional setting for whether to display the heading or not */
  showHeading: PropTypes.bool,
  /** Optional setting for making the detail sticky at the top of the screen */
  sticky: PropTypes.bool,
  /** Optional setting for the type of detail widget to render */
  variant: PropTypes.oneOf(["bordered", "inset"])
}

RaceDetail.defaultProps = {
  showHeading: true,
  sticky: false,
  variant: "bordered"
}

export default RaceDetail;