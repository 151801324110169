import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Spinner() {
  return (
    <div className="hell-spinner py-3 mx-3 text-primary">
      <FontAwesomeIcon fixedWidth icon="running"/>
    </div>
  );
}

Spinner.propTypes = {};

Spinner.defaultProps = {};

export default Spinner;