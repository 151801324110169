const defaultState = {
  runners: [],
};

const support = (state = defaultState, action) => {
  switch(action.type){
    case "SET_RUNNERS":
      state = {
          ...state,
          runners: action.payload
      };
      break;
    default:
      break;
  }

  return state;
}

export default support;