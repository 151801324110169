import React from 'react';
import PropTypes from "prop-types";

import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getRaceDate } from "../utils/raceUtility";

function RaceListItem({handleSelect, race, selected}) {

  const handleClick = () => {
    handleSelect(race.id);
  }

  return (
    <li
      className={classnames("list-group-item", "p-0", "m-0", selected ? "active text-light" : "")}
      aria-current={selected}
    >
      <button
        type="button"
        className={classnames("btn d-block w-100 h-100 p-2", selected ? "btn-primary" : "btn-light")}
        onClick={handleClick}
      >
        <div className="row">
          <div className="col-9 d-flex align-items-center justify-content-start">
            <h3 className="m-0">{getRaceDate(race.date._seconds)}</h3>
            {race.participants && !!race.participants.length && (
              <div className="d-inline-block m-3">
                <span
                  className={classnames("badge rounded-pill", selected ? "bg-white text-dark" : "bg-secondary text-light")}
                >
                  {race.participants.length}
                </span>
                {" "}Participants
              </div>
            )}
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center">
            {selected && <FontAwesomeIcon icon="arrow-right" />}
          </div>
        </div>
      </button>
    </li>
  );
}

RaceListItem.propTypes = {
  /** the handler for when the race is selected */
  handleSelect: PropTypes.func.isRequired,
  /** the race to render data for */
  race: PropTypes.object.isRequired,
  /** indicates if this race is selected */
  selected: PropTypes.bool
}

RaceListItem.defaultProps = {
  selected: false
}

export default RaceListItem;